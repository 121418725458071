@use 'theme';
@use 'breakpoints';
@use 'borders';
@use 'display';
@use 'fonts';

$offset: 36px;

.wrapper {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.icon {
  display: flex;
  align-items: center;
  color: theme.get('shopColor');
}

.desktop {
  @include display.hideOnMobile(flex);
}

.indicator {
  font-weight: fonts.weight('bold');
  margin-right: theme.spacing(0.5);
}

.small {
  @extend .wrapper;
  gap: theme.spacing(3);
  
  @include breakpoints.up('small') {
    justify-content: space-between;
    flex-direction: row;
    gap: theme.spacing(2);
  }
}

.big {
  @extend .wrapper;
  gap: theme.spacing(6);

  @include breakpoints.up('small') {
    display: grid;
    gap: theme.spacing(6);
    grid-template-columns: repeat(2, 1fr);
  }
}

.item {
  position: relative;
  display: flex;
  align-items: center;
  gap: theme.spacing(2);
  scroll-margin-top: theme.spacing(2.5);
  z-index: var(--zIndex);

  .big & {
    flex-direction: column;
    align-items: flex-start;
  }
  
  @include breakpoints.up('small') {
    flex-direction: column;
    align-items: flex-start;
    flex: 1;

    .small &:first-of-type {
      align-items: center;
    }
  };
}

.header {
  display: flex;
  color: theme.get('shopColor');
  gap: theme.spacing(1.25);
  align-items: center;
}

.arrowBackground {
  position: absolute;
  clip-path: polygon(0% 0%, calc(100% - 22px) 0%, 100% 50%, calc(100% - 22px) 100%, 0% 100%);
  height: 54px;
  width: calc(100% + 2px);
  background-color: theme.palette('white');
  z-index: -1;

  .item:first-of-type & {
    width: calc(100% + 4px);
  }

  .small & {
    @include display.up('medium');
  }
}

.headerBackground {
  display: none;

  @include breakpoints.up('medium') {
    display: flex;
    width: 100%;
    height: 54px;
    padding: theme.spacing(1.5, 2.5);
    background: linear-gradient(90deg, rgba(theme.get('activeColorRgb'), 0.03) 0%, rgba(theme.get('activeColorRgb'), 0.15) 100%);

    .item:not(:last-of-type) & {
      width: calc(100% + theme.spacing(1));
      clip-path: polygon(0% 0%, calc(100% - 22px) 0%, 100% 50%, calc(100% - 22px) 100%, 0% 100%);
    }

    .item:first-of-type & {
      border-radius: borders.radius('xMd', 0, 0, 'xMd');
      width: 100%;
    }

    .item:last-of-type & {
      border-radius: borders.radius(0, 'xMd', 'xMd', 0);
    }

    .item:not(:first-of-type) & {
      padding-left: $offset;
      width: calc(100% + $offset);
      margin-left: -$offset;
    }
  }
};

.headerText {
  color: theme.get('textColor');
}

.mobileHeader {
  @include display.down('medium', flex);
  align-items: center;
  gap: theme.spacing(1);
}

.divider {
  width: 1px;
  height: 20px;
  background-color: theme.get('shopColor');
}

.smallDescription {
  @include breakpoints.up('medium') {
    max-width: 229px;
  }
}

.longDescription {
  margin-top: theme.spacing(2);
}

.more {
  display: flex;
  align-items: center;
  gap: theme.spacing(1);
  margin-top: theme.spacing(1);
  color: theme.get('darkActiveColor');
  cursor: pointer;
}