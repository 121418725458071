@use 'theme';
@use 'breakpoints';
@use 'display';
@use 'transitions';

.container {
  position: relative;
  width: 100%;
  overflow-x: hidden;

  & img {
    width: 100%;
  }
}

.slide {
  display: flex;
}

.banner {
  display: block;
  width: 100%;

  & .bannerDesktop {
    @include display.hideOnMobile(flex);
  }
  
  & .bannerMobile {
    @include display.hideOnDesktop(flex);
    width: 100%;
  
    & picture {
      width: 100%;
    }
  }
}

.arrow {
  opacity: var(--opacity);

  @include breakpoints.up('largeMedium') {
    .container & {
      opacity: 0.5;
      transition: transitions.get(('opacity', 'background-color'));
    }
    .container:hover & {
      opacity: var(--opacity);
    }
  }
}
