@use 'theme';
@use 'breakpoints';
@use 'typography';
@use 'display';

.wrapper {
  display: flex;
  flex-direction: column-reverse;
  text-align: center;
  align-items: center;
  gap: theme.spacing(3);
  margin-top: theme.spacing(3);

  @include breakpoints.up('medium') {
    flex-direction: row;
    text-align: left;
    gap: theme.spacing(10);
  }
}

.leftSide {
  display: flex;
  flex-direction: column;
  gap: theme.spacing(2);
}

.header {
  position: relative;
  @include breakpoints.up('small') {
    @include typography.get('huge');
  }
}

.divider {
  position: absolute;
  z-index: -1;
  width: 180px;
  height: 9px;
  background: linear-gradient(254.7deg, rgba(theme.get('activeColorRgb'), 0.5) 0%, rgba(theme.get('activeColorRgb'), 0.2) 100%);
  left: calc(50% - 90px);
  bottom: 2px;

  @include breakpoints.up('small') {
    width: 303px;
    height: 15px;
    left: calc(50% - 150px);
    bottom: 0;
  }

  @include breakpoints.up('medium') {
    left: -4px;
  }
}

.image {
  max-width: 100%;
  max-height: 190px;

  img {
    max-width: 100%;
    max-height: 190px;
  }

  @include breakpoints.up('medium') {
    min-width: 50%;
  }
}