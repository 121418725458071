@use 'theme';
@use 'fonts';
@use 'breakpoints';

.wrapper {
  font-size: fonts.size('large');
}

.banner {
  margin: theme.spacing(6, 0);

  @include breakpoints.up('small') {
    margin: theme.spacing(0, 0, -4);
  }

  @include breakpoints.up('medium') {
    margin: theme.spacing(2, 0, -2);
  }

  @include breakpoints.up('largeMedium') {
    margin: theme.spacing(4, 0, 0);
  }

  @include breakpoints.up('large') {
    margin: theme.spacing(6, 0);
  }

  & .arrow {
    @include breakpoints.up('small') {
      bottom: 56px;
    }
  
    @include breakpoints.up('medium') {
      bottom: 48px;
    }
  
    @include breakpoints.up('largeMedium') {
      bottom: 32px;
    }
  
    @include breakpoints.up('large') {
      bottom: 24px;
    }
  
    @include breakpoints.up('xLarge') {
      bottom: 16px;
    }
  }

  & img {
    width: 100%;
  }
}

.smallSection {
  margin: theme.spacing(4, 0, 6);
}

.section {
  @extend .smallSection;

  @include breakpoints.up('small') {
    margin: theme.spacing(4, 0, 10);
  }
}

.sectionSpacing {
  margin-top: theme.spacing(6);
}
