@use 'theme';
@use 'borders';
@use 'breakpoints';

.wrapper {
  display: flex;
  margin: theme.spacing(4, 0, 0);
  align-items: center;
  flex-direction: column;

  @include breakpoints.up('medium') {
    flex-direction: row;
  }
}

.imagesWrapper {
  display: flex;
  justify-content: center;
  gap: theme.spacing(4);
  width: 100%;

  @include breakpoints.up('small') {
    justify-content: space-evenly;
  }

  @include breakpoints.up('medium') {
    gap: theme.spacing(7);
    width: auto;
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
}

.image {
  display: flex;
  align-items: center;

  & img {
    width: 100%;

    @include breakpoints.up('medium') {
      width: auto;
    }
  }
}

.lineHeight {
  line-height: 14px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: theme.spacing(0.875);

  @include breakpoints.up('medium') {
    justify-content: flex-start;
  }
}

.logoText {
  margin-left: theme.spacing(2);
  padding-left: theme.spacing(2);
  border-left: borders.get($color: theme.get('darkDividerColor'));
  width: 176px;
}

.descriptionWrapper {
  text-align: center;
  margin-top: theme.spacing(2);

  @include breakpoints.up('small') {
    max-width: 780px;
  }

  @include breakpoints.up('medium') {
    margin-top: theme.spacing(0);
    margin-left: theme.spacing(5);
    text-align: left;
  }

  @include breakpoints.up('largeMedium') {
    margin-left: theme.spacing(9.625);
  }
}
