@use 'theme';
@use 'breakpoints';

.headerWrapper {
  margin-bottom: theme.spacing(10);

  @include breakpoints.up('small') {
    margin-bottom: theme.spacing(15);
  }
}

.advantages {
  width: 95%;
  margin: theme.spacing(0, 'auto');
  display: flex;
  flex-direction: column;
  gap: theme.spacing(6, 0);

  @include breakpoints.up('small') {
    display: grid;
    grid-template-columns: repeat(calc(var(--childrenLength, 0) / 2), 1fr);
    grid-template-rows: repeat(calc(var(--childrenLength, 0) / 2), 1fr);
    gap: theme.spacing(10, 0);
  }

  @include breakpoints.up('medium') {
    grid-template-columns: repeat(var(--childrenLength, 0), 1fr);
    grid-template-rows: 1fr;
    gap: theme.spacing(13, 12);
  }
}
