@use 'theme';
@use 'breakpoints';

.title {
  text-transform: uppercase;
}

@include breakpoints.up('medium') {
  .wrapper {
    margin-top: theme.spacing(4);
    justify-content: left;
  }

  .container {
    margin: theme.spacing(0);
  }

  .title {
    max-width: 350px;
    text-align: left;
  }

  .subtitle {
    margin: theme.spacing(3, 'auto', 0);
    max-width: 530px;
    text-align: left;
  }
}