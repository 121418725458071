$typography: (
  'h1': (
    'font-size': 22px,
    'line-height': 30px,
    'font-weight': 700,
  ),
  'h2': (
    'font-size': 18px,
    'line-height': 26px,
    'font-weight': 400,
  ),
  'h3': (
    'font-size': 15px,
    'line-height': 23px,
    'font-weight': 700,
  ),
  'h4': (
    'font-size': 14px,
    'line-height': 22px,
    'font-weight': 400,
  ),
  'body1': (
    'font-size': 15px,
    'line-height': 23px,
    'font-weight': 400,
  ),
  'body2': (
    'font-size': 13px,
    'line-height': 21px,
    'font-weight': 400,
  ),
  'body3': (
    'font-size': 13px,
    'line-height': 21px,
    'font-weight': 400,
  ),
  'caption1': (
    'font-size': 12px,
    'line-height': 18px,
    'font-weight': 400,
  ),
  'caption2': (
    'font-size': 11px,
    'line-height': 19px,
    'font-weight': 400,
  ),
  'label': (
    'font-size': 10px,
    'line-height': 18px,
    'font-weight': 400,
  ),
  'huge': (
    'font-size': 40px,
    'line-height': 50px,
    'font-weight': 700,
    'letter-spacing': -0.025em,
  ),
  'xHuge': (
    'font-size': 60px,
    'line-height': 72px,
  ),
);

@mixin get($variant) {
  @each $property, $value in map-get($typography, $variant) {
    #{$property}: $value;
  }
}
