@use 'theme';
@use 'transitions';

.arrow {
  position: absolute;
  bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: theme.palette('gray.100');
  padding: theme.spacing(1);
  border-radius: 50%;
  width: 48px;
  height: 48px;
  cursor: pointer;
  transition: transitions.get(('opacity', 'background-color'));
  --opacity: 1;

  &:hover {
    background-color: theme.palette('gray.75');
  }
}

.left {
  right: 72px;
}

.right {
  right: 16px;
}

.disabled {
  --opacity: 0.5;
  cursor: default;
}

.arrowIcon {
  display: flex;
  width: 9px;
  height: 15px;

  .left & {
    margin-left: theme.spacing(-0.25);
    transform: rotate(180deg);
  }

  .right & {
    margin-left: theme.spacing(0.5);
  }
}
