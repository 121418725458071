@use 'theme';
@use 'breakpoints';

.wrapper {
  height: 100%;
  width: 100%;

  &.center {
    text-align: center;
  }
}

.iconBox {
  display: flex;
  align-items: flex-end;
  justify-content: center;

  & svg {
    color: theme.get('iconColor');
  }
}

.icon {
  display: flex;
  justify-content: center;
  margin-bottom: theme.spacing(6);

  &.small {
    margin-bottom: theme.spacing(3);
  }
}

.title {
  margin-bottom: theme.spacing(1);
}

.description {
  display: block;
  text-align: center;

  @include breakpoints.up('small') {
    text-align: left;

    &.centerDescription {
      text-align: center;
    }
  }
}
