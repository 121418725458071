@use 'theme';
@use 'breakpoints';

@layer utilities {
  .skeleton {
    width: 100%;
    height: var(--recommendedProductsSkeletonHeight);
    max-height: var(--recommendedProductsSkeletonHeight);
  
    @include breakpoints.up('small') {
      height: var(--recommendedProductsSkeletonHeightSmall);
      max-height: var(--recommendedProductsSkeletonHeightSmall);
      aspect-ratio: 1;
      & svg {
        width: 400px;
      }
    }
  
    @include breakpoints.up('medium') {
      height: var(--recommendedProductsSkeletonHeightMedium);
      max-height: var(--recommendedProductsSkeletonHeightMedium);
    }
  }
}
