@use 'theme';
@use 'breakpoints';
@use 'scroll';

.root {
  margin-top: theme.spacing(4);
}

.logos {
  display: flex;
  justify-content: space-between;
  flex: 1;
  overflow-x: auto;
  gap: theme.spacing(2);
  padding: theme.spacing(0, 2);
}

@include breakpoints.up('medium') {
  .wrapper {
    justify-content: right;
  }

  .container {
    margin: theme.spacing(0);
  }

  .title {
    text-align: left;
  }

  .subtitle {
    margin: theme.spacing(3, 'auto', 0);
    max-width: 530px;
    text-align: right;
  }
}