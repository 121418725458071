@use 'theme';
@use 'breakpoints';
@use 'typography';

.wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px;
  width: 100%;
  padding: theme.spacing(3);

  @include breakpoints.up('medium') {
    padding: theme.spacing(8);
  }
}

.container {
  position: relative;
  color: theme.palette('white');
  text-align: center;
  margin: theme.spacing(0, 'auto');
  z-index: 1;
}

.title {
  @include breakpoints.up('small') {
    @include typography.get('xHuge');
  }
}

.subtitle {
  max-width: 600px;
  margin: theme.spacing(6, 'auto', 0);
  white-space: pre-line;

  @include breakpoints.up('small') {
    @include typography.get('h2');
  }
}

.image {
  & img {
    border-radius: theme.get('heroSectionRadius');
  }
}
